import {
	Card,
	CardImg,
	Row,
	Col,
	Container,
	jumbotron,
	Button,
	label,
	input,
	ul,
	li,
	Nav,
	Navbar,
} from "react-bootstrap";
import logo from "./gembit.png";
import bg from "./bg.png";
import { FiCheckCircle } from "react-icons/fi";

const Home = () => {
	return (
		<Container
			className="mt-0 ml-0 pl-0"
			style={{
				backgroundSize: "cover",
				backgroundImage: `url(${bg})`,
				backgroundAttachment: "fixed",
				maxWidth: "100%",
				//height: "100vh",
			}}
		>
			<Row>
				<jumbotron className="jumbotron-fluid" style={{ padding: "0" }}>
					<Container fluid className="p-5">
						<h1 className="text-white btx">IDEAS FOR CONVENIENCE</h1>
						<p className="mt-4 text-white">
							Solutions for your home automation needs
						</p>
					</Container>
				</jumbotron>
			</Row>
			<Row className="mt-4"></Row>
		</Container>
	);
};

export default Home;
